import React, { Fragment, useContext, useEffect, useState, useRef } from 'react';
import ProcessContext from '../../../contexts/process/ProcessContext';
import UIContext from '../../../contexts/ui/UIContext';
import AuthContext from '../../../contexts/auth/AuthContext';
import WeighingPanel from './WeighingPanel';
import WeighingTray from './WeighingTray';
import Loading from '../../ui-elements/Loading';
import StatusBarButton from '../../layout/StatusBarButton';
import BackButton from '../../layout/BackButton';
import Scale from './Scale';
import { ENABLE_PRINTING, DISABLE_PRINTING, REPRINT, MULTIHARVEST } from '../../../contexts/types/processControlTypes';
import { HttpAgent } from '../../../utility/HttpAgent';
import { HELP_DESK_LINK } from '../../../constants/helpDesk';
import { useSignalRContext } from '../../../contexts/signalR/SignalRProvider';

const WeighingContainer = ({ enablePrinting = true, processType = 'HARVESTING', backwardTo }) => {
  const {
    activeProcessId,
    updateOccupancy,
    continueProcess,
    controlProcess,
    additionalProcessInformation,
    loadOccupancy,
    notifySuccess,
    notifyError,
    requestedInformationType,
    occupancyArray,
  } = useContext(ProcessContext);
  const { loading } = useContext(UIContext);
  const { currentTenant, currentContainer } = useContext(AuthContext);
  const [withPrint, setWithPrint] = useState(enablePrinting);
  const [printed, setPrinted] = useState(false);
  const [cameraState, setCameraState] = useState(false);
  const [analysisDataFlag, setAnalysisDataFlag] = useState(false);
  const [weighedPlantDisplayText, setWeighedPlantDisplayText] = useState('');
  const [plantBlockBeingWeighed, setPlantBlockBeingWeighed] = useState(null);
  const [backIsForbidden, setBackIsForbidden] = useState(false);
  const [firstTime, setFirstTime] = useState(true);

  const cameraStateUrl = `/Api/V1/Tenants/${currentTenant?.tenantId}/Containers/${currentContainer?.id}/Devices/${currentContainer.weighingCameraId}/State`;

  const { registerClientFunction, isReady } = useSignalRContext();
  
  const initialWeighingData = {
    weight: 0.0,
    timestamp: new Date().toISOString(),
    alibiIdentifier: "",
    scaleId: currentContainer?.scaleId,
    weighingCameraOperationId: null,
  };

  const [processedWeighingData, setProcessedWeighingData] = useState(initialWeighingData);

  // Use ref for isWaitingForZero to persist across renders without triggering a re-render
  const isWaitingForZeroRef = useRef(false);

  const processWeightUpdate = async (weightUpdate) => {
    const { weight, timestamp, alibiIdentifier, weighingCameraOperationId, scaleId } = weightUpdate;    
    // Check is Zero required
    if (isWaitingForZeroRef.current) {
      if (weight === 0) {
        // await handlePrint();
        await handleConfirm();
        // Set the processed data when weight is zero
        setProcessedWeighingData({
          weight,
          timestamp,
          alibiIdentifier,
          scaleId,
          weighingCameraOperationId,
        });
        // Stop waiting for zero
        isWaitingForZeroRef.current = false;
      }
    } else {
      // We're no longer waiting for zero
      if (weight > 0) {
        // Update processedWeighingData with new weight data
        setProcessedWeighingData({
          weight,
          timestamp,
          alibiIdentifier,
          scaleId,
          weighingCameraOperationId,
        });
        // Start waiting for zero again
        isWaitingForZeroRef.current = true;
        // await handlePrint();
      }
    }
  };

  useEffect(() => {
    load();
    updateCameraState();

    return () => {
      updateOccupancy([]);
    };
  }, []);

  useEffect(() => {
    if (isReady) {
      debugger;
      registerClientFunction("scaleHub", "WeightUpdate", processWeightUpdate);
    }
  }, [isReady, additionalProcessInformation]);

  useEffect(() => {
    let channelIndex = Math.floor(additionalProcessInformation?.plantSlotOrdinalNumber / 100);
    
    if (additionalProcessInformation?.sourceEntity.toLowerCase().startsWith('p') && occupancyArray[channelIndex] != undefined) {
      let elementBeingWeighed = occupancyArray[channelIndex].plantSlotBlocks.filter(
        (block) => block.startPlantSlotOrdinalNumber === additionalProcessInformation?.plantSlotOrdinalNumber
      );
      setPlantBlockBeingWeighed(elementBeingWeighed);
    }
  }, [additionalProcessInformation, cameraState, analysisDataFlag]);

  const createWeighingBody = () => {
    const timestampCheck = processedWeighingData?.timestamp;
    console.log("Timestamp: ", processedWeighingData?.timestamp);
    return {
      weight: processedWeighingData?.weight,
      alibiIdentifier: processedWeighingData?.alibiIdentifier,
      timestamp: timestampCheck ? new Date(processedWeighingData?.timestamp).toISOString() : null,
      weighingCameraOperationId: processedWeighingData?.weighingCameraOperationId,
    };
  };

  const handleConfirm = async (event, multiHarvest = false) => {
    try {
      setBackIsForbidden(true);
      if (withPrint) {
        setPrinted(false);
        await continueProcess(activeProcessId, { ...createWeighingBody(), multiHarvest });
      } else {
        setPrinted(true);
        await continueProcess(activeProcessId, { ...createWeighingBody(), multiHarvest });
      }
      resetWeighingData();
    } catch (error) {
      notifyError(<>We have encountered an issue while confirming the process. Please contact the support team {HELP_DESK_LINK}.</>);
      console.error(error);
    } finally {
      setAnalysisDataFlag(false);
      setFirstTime(true);
    }
  };

  const resetWeighingData = () => {
    setProcessedWeighingData(initialWeighingData);
  };

  const handlePrint = async () => {
    try {
      setPrinted(true);
      await continueProcess(activeProcessId, { ...createWeighingBody(), operationId: processedWeighingData?.weighingCameraOperationId });
      if (withPrint) {
        notifySuccess('Label printed');
      }
    } catch (error) {
      notifyError(<>We have encountered an issue while printing the label. Please contact the support team {HELP_DESK_LINK}.</>);
      console.error(error);
    }
  };

  const handleReprint = async () => {
    try {
      await controlProcess(activeProcessId, REPRINT, true, 'Label printed', createWeighingBody());
    } catch (error) {
      notifyError(<>We have encountered an issue while reprinting. Please contact the support team {HELP_DESK_LINK}.</>);
      console.error(error);
    }
  };

  const load = async () => {
    if (additionalProcessInformation) {
      await loadOccupancy(additionalProcessInformation.sourceEntity, 'panel');
    }
  };

  const updateCameraState = () => {
    HttpAgent.get(cameraStateUrl).then((response) => {
      const newState = response.data ? true : false;
      setCameraState(newState);
    });
  };

  const renderDependingOnAnalysisState = () => {
    return (
      <Fragment>
        <BackButton disabled={backIsForbidden} navigateTo={backwardTo} />
        {additionalProcessInformation && !loading ? (
          additionalProcessInformation.sourceEntity.toLowerCase().startsWith('t') ? (
            <WeighingTray setScaleTitle={setWeighedPlantDisplayText} />
          ) : (
            <WeighingPanel setScaleTitle={setWeighedPlantDisplayText} />
          )
        ) : (
          <Loading fullScreen />
        )}
        <Scale
          title={weighedPlantDisplayText}
          weighingData={processedWeighingData}
          element={plantBlockBeingWeighed}
          layout={additionalProcessInformation && additionalProcessInformation.sourceEntity.toLowerCase().startsWith('t') ? 'scale-tray' : 'scale'}
        />
        {renderControlButtons()}
      </Fragment>
    );
  };

  const renderControlButtons = () => {
    
    if (requestedInformationType === 14) {
      return (
        <Fragment>
          {/* {additionalProcessInformation?.printing && (
            <StatusBarButton
              label='Reprint'
              icon='fas fa-print'
              clickHandler={handleReprint}
              type='inline'
              statusSlot={2}
              disabled={!processedWeighingData?.weight}
            />
          )} */}
          <StatusBarButton
            label='Print'
            icon='fas fa-print'
            clickHandler={handleReprint}
            type='inline'
            statusSlot={4}
            disabled={!processedWeighingData?.weight}
          />
          <StatusBarButton
            label={'Confirm'}
            icon='fas fa-check'
            reversed={true}
            clickHandler={handleConfirm}
            statusSlot={5}
            disabled={!processedWeighingData?.weight}
            size={''}
          />
        </Fragment>
      );
    }

    if (requestedInformationType == 4) {
      return (
        <Fragment>
          <StatusBarButton
            label='Confirm'
            icon='fas fa-check'
            reversed={true}
            clickHandler={handleConfirm}
            statusSlot={5}
            disabled={!processedWeighingData?.weight}
          />
        </Fragment>
      );
    }
  };

  return renderDependingOnAnalysisState();
};

export default WeighingContainer;
